import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="" />
    <div class="row" style={{textAlign:`center`, marginTop:`1rem`,}}>
      <div class="twelve column">
        <h4>Launching soon... Curious?</h4>
        <p>Subscribe below and be the first to know!</p>
        <iframe title="Subscribe for announcement" width="540" height="650" src="https://d4df7987.sibforms.com/serve/MUIEAGl5CLIIj-0g4T2dDtkKl8WhaN6jkMaUDznaK8HxoGrTKERYePUqZ5-LqE9G1hQgrw_DAHwqeogvXIXCk-6-Gfgi4fRqimsgu3yW8AurogPLE5yqlnPzr_16OnBDEt8Em8i5xYkw8hhQY0d8UsuXxMtjx7OvvRGb7a34W0-ErrEMOIe3Vw0rLaemabaDQlh5EovVtyHeJDot" frameborder="0" scrolling="auto" allowfullscreen 
        style={{display: `block`,
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: `100%`,}}
        ></iframe>
      </div>
    </div>
  </Layout>
)


export default IndexPage
